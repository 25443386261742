import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
const InitPage = ({ children, title }) => {
    // 设置标题
    document.title = title;
    useEffect(() => {
        const initMpPage = async () => {
            // 获取公众号权限
            // await setMpSignature();
            // const share = {
            //   title: "京心健康院内陪护权益",
            //   desc: "精心呵护，安心陪伴",
            //   link: window.location.origin + "/right/home",
            //   imgUrl: 'https://cdn.happyhealths.com/thirdParty/jingxin/logo.png',
            // }
            // // 朋友圈
            // wx.onMenuShareTimeline(share);
            // // 微信好友
            // wx.onMenuShareAppMessage(share);
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        initMpPage();
    }, []);
    return _jsx("div", { children: children });
};
export default InitPage;
