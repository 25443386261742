import { jsx as _jsx } from "react/jsx-runtime";
import { DotLoading } from 'antd-mobile';
import styles from './styles.module.less';

import { useState, useEffect } from 'react';
import * as cx from 'classnames';
export const SuspenseFallback = (props) => {
    const { delay } = props;
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);
    if (!show) {
        return null;
    }
    return (_jsx("div", { className: cx(styles.fallback, styles.loading), children: _jsx(DotLoading, {}) }));
};
