/* eslint-disable @typescript-eslint/no-explicit-any */
import { Toast } from 'antd-mobile';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
const axios = Axios.create();
const axiosWithCache = setupCache(Axios.create());
const getConfig = (config) => {
    const { baseURL = APIURL, headers: rawHeaders } = config;
    const headers = {
        'Content-Type': 'application/json',
        ...rawHeaders,
    };
    return {
        ...config,
        baseURL,
        headers,
    };
};
const request = async (options, extra) => {
    const { cache } = extra || {};
    const config = getConfig(options);
    try {
        const promise = cache
            ? axiosWithCache.request({
                ...config,
                id: cache.id,
                cache: cache.config,
            })
            : axios.request(config);
        const res = await promise;
        // if (res.data.code !== 0) {
        //   throw new Error(res.data.msg);
        // }
        return res.data;
    }
    catch (err) {
        Toast.show(err instanceof Error ? err.message : '服务端请求错误');
        throw new Error('服务端请求错误');
    }
};
export default request;
