/**
 * 处理类 Promise 对象，用于 Suspense 组件
 */
export const resolve = (thenable) => {
    if (thenable.status === 'fulfilled') {
        return thenable.value;
    }
    if (thenable.status === 'rejected') {
        throw thenable.reason;
    }
    // 如果有 status 属性，那么可能已经被用户自定义实现了，默认将其视为 pending 状态
    if (typeof thenable.status === 'string') {
        throw thenable;
    }
    // 如果没有 status 属性，那么需要自己实现
    const trackedThenable = thenable;
    const pendingThenable = trackedThenable;
    pendingThenable.status = 'pending';
    pendingThenable.then((fulfilledValue) => {
        if (pendingThenable.status === 'pending') {
            const fulfilledThenable = trackedThenable;
            fulfilledThenable.status = 'fulfilled';
            fulfilledThenable.value = fulfilledValue;
        }
    }, (error) => {
        if (pendingThenable.status === 'pending') {
            const rejectedThenable = trackedThenable;
            rejectedThenable.status = 'rejected';
            rejectedThenable.reason = error;
        }
    });
    // 处理 Promise.resolve() 的情况
    if (trackedThenable.status === 'fulfilled') {
        const fulfilledThenable = trackedThenable;
        return fulfilledThenable.value;
    }
    if (trackedThenable.status === 'rejected') {
        const rejectedThenable = trackedThenable;
        throw rejectedThenable.reason;
    }
    throw thenable;
};
