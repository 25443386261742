import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createErrorBlock } from 'antd-mobile';
import { FileWrongOutline, SearchOutline } from 'antd-mobile-icons';
import styles from './styles.module.less';

import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import * as cx from 'classnames';
export const ErrorBlock = createErrorBlock({
    default: _jsx(FileWrongOutline, { className: styles.error }),
    empty: _jsx(SearchOutline, { className: styles.error }),
});
export const ErrorFallback = (props) => {
    const { error, title, description } = props;
    const navigate = useNavigate();
    const onReload = useCallback(() => navigate(0), [navigate]);
    return (_jsxs("div", { className: cx(styles.fallback, styles.error), children: [_jsx(ErrorBlock, { status: "default", title: error?.message || title, description: description }), _jsx("button", { type: "button", onClick: onReload, className: styles.btn, children: "\u5237\u65B0" })] }));
};
