import { lazy } from "react";
import { parseModule } from "./utils";
export { usePageData } from "./utils";
/**
 * 权益系统的所有路由
 */
export const rightRoutes = {
    ...parseModule(import("../layouts/right")),
    children: [
        {
            title: "健康权益",
            path: "/right/home",
            ...parseModule(import("../pages/right/Home")),
        },
        {
            title: "我的权益",
            path: "/right/my-rights",
            ...parseModule(import("../pages/right/MyRights")),
        },
        {
            title: "个人中心",
            path: "/right/user-center",
            ...parseModule(import("../pages/right/UserCenter")),
        },
        {
            title: "健康权益",
            path: "/right/activate",
            ...parseModule(import("../pages/right/Activate")),
        },
        {
            title: "健康权益",
            path: "/right/detail",
            ...parseModule(import("../pages/right/RightDetail")),
        },
        {
            title: "绑定手机号",
            path: "/right/user-center/bind-phone",
            ...parseModule(import("../pages/right/user-center/BindPhone")),
        },
        {
            title: "实名认证",
            path: "/right/user-center/real-name-auth",
            ...parseModule(import("../pages/right/user-center/RealNameAuth")),
        },
        {
            title: "关于京心",
            path: "/right/user-center/about-jingxin",
            ...parseModule(import("../pages/right/user-center/About")),
        },
        {
            title: "服务条款",
            path: "/right/user-center/service-agreement",
            ...parseModule(import("../pages/right/user-center/ServiceAgreement")),
        },
        {
            title: "隐私协议",
            path: "/right/user-center/privacy-agreement",
            ...parseModule(import("../pages/right/user-center/PrivacyAgreement")),
        },
    ],
};
export const commonRoutes = [
    {
        title: "我的权益",
        path: "/thirdParty/sinosig/rights",
        component: lazy(() => import("../pages/sinosig/rights/index")),
    },
    {
        title: "专属客服",
        path: "/right/apply/hmwj",
        component: lazy(() => import("../pages/right/apply/Hmwj")),
    },
    // 禾沐万家
    // {
    //   title: "权益查询",
    //   path: "/thirdParty/hmwj/search",
    //   component: lazy(() => import("../pages/hmwj/search/index")),
    // },
    // {
    //   title: "权益列表",
    //   path: "/thirdParty/hmwj/list",
    //   component: lazy(() => import("../pages/hmwj/list/index")),
    // },
    {
        title: "权益详情",
        path: "/thirdParty/hmwj/detail",
        component: lazy(() => import("../pages/hmwj/detail/index")),
    },
    {
        title: "权益绑定",
        path: "/thirdParty/hmwj/edit",
        component: lazy(() => import("../pages/hmwj/edit/index")),
    },
];
export const routes = [...commonRoutes];
