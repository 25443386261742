import { isIOS } from './index';
import request from './request';
let ENRTY_URL = '';
let isMp = false;
export const APP_ID = 'wx88179537c279b2a1';
export const initMp = () => {
    ENRTY_URL = window.location.href;
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) ||
        ua.match(/WechatDevTools/i) ||
        ua.match(/WindowsWechat/i)) {
        isMp = true;
    }
    else {
        isMp = false;
    }
};
export const getMpInfo = () => ({ entryUrl: ENRTY_URL, isMp });
// 签名
export const setMpSignature = () => new Promise((resolve, reject) => {
    request({
        url: 'wechat/jsapi/sign',
        baseURL: 'https://tapi.happyhealths.com',
        method: 'post',
        data: {
            url: isIOS() ? ENRTY_URL : window.location.href,
        },
    })
        .then((res) => {
        const { appId, timestamp, nonceStr, signature, jsApiList } = res.data;
        wx.config({
            appId,
            timestamp,
            nonceStr,
            signature,
            jsApiList,
        });
        wx.ready(() => {
            resolve('');
        });
    })
        .catch((err) => {
        reject(err);
    });
});
export const chooseMpImgs = (count = 9) => new Promise((resolve, reject) => {
    wx.chooseImage({
        count,
        sizeType: ['compressed', 'original'],
        sourceType: ['album', 'camera'],
        success: (res) => {
            resolve(res.localIds);
        },
        fail: (err) => {
            reject(err);
        },
    });
});
/** 将 localId 转化为 base64 */
export const getLocalImgData = (localId) => new Promise((resolve, reject) => {
    wx.getLocalImgData({
        localId,
        success: (res) => {
            let { localData } = res;
            if (localData.indexOf('data:image') === -1) {
                localData = `data:image/jpeg;base64,${localData}`;
            }
            else {
                localData.replace('jgp', 'jpeg');
            }
            resolve(localData);
        },
        fail: (err) => {
            reject(err);
        },
    });
});
/** 从后端获取跳转链接 */
export const getLoginUrl = async (redirect) => {
    const res = await request({
        url: `/wechat/${APP_ID}/login`,
        method: 'get',
        params: { frontend: redirect },
    });
    return res.data.url;
};
/** 微信登录 */
export const mpLogin = async (redirect) => {
    // const redirectUri = encodeURIComponent(redirect);
    const url = await getLoginUrl(redirect);
    window.location.href = url;
};
export default {
    getMpInfo,
    initMp,
    chooseMpImgs,
};
