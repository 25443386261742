import { jsx as _jsx } from "react/jsx-runtime";

import { useState, useCallback, useEffect } from 'react';
export const SmsCodeButton = (props) => {
    const { onClick } = props;
    const [count, setCount] = useState(0);
    const [, setTimer] = useState(null);
    const onButtonClick = useCallback(async () => {
        if (count > 0) {
            return;
        }
        const res = await onClick();
        if (res) {
            setCount(60);
        }
    }, [count, onClick]);
    useEffect(() => {
        if (count > 0) {
            setTimer(window.setTimeout(() => {
                setCount((prev) => prev - 1);
            }, 1000));
        }
        else {
            setTimer(null);
        }
    }, [count]);
    return (_jsx("button", { type: "button", onClick: onButtonClick, children: count > 0 ? `${count} 秒后重试` : '获取验证码' }));
};
