import { jsx as _jsx } from "react/jsx-runtime";
import { Toast } from 'antd-mobile';

import * as React from 'react';
/**
 * 错误包裹组件，用于 Layout 组件中，防止错误导致整个页面崩溃
 */
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }
    componentDidCatch(error, info) {
        // Example "info.componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        Toast.show({
            icon: 'fail',
            content: error.message || JSON.stringify(error),
        });
    }
    render() {
        const { fallback: Fallback, children } = this.props;
        const { hasError, error } = this.state;
        // You can render any custom fallback UI
        return hasError ? _jsx(Fallback, { error: error }) : children;
    }
}
