import { matchPath } from 'react-router-dom';
// 是否为 ios 环境
export const isIOS = () => /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
export const getSearchParams = (name, url) => {
    const { searchParams } = new URL(url || window.location.href);
    return searchParams.get(name);
};
export const isMatchRoutePath = (path, url) => {
    const { pathname } = new URL(url || window.location.href);
    return matchPath(path, pathname);
};
export const deleteUrlParams = (keys) => {
    const url = new URL(window.location.href);
    keys.forEach(key => {
        url.searchParams.delete(key);
    });
    const newSearchString = url.searchParams.toString() ? `?${url.searchParams.toString()}` : "";
    const newUrl = `${url.origin}${url.pathname}${newSearchString}${url.hash}`;
    window.history.replaceState(null, "", newUrl);
};
export const phoneMosaic = (phone) => {
    let reg = /(\d{3})\d{4}(\d{4})/;
    return phone.replace(reg, '$1****$2');
};
export const sleep = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve("");
        }, 2000);
    });
};
export default { isIOS };
