import { getDynamicExport } from '@/utils/module';
import { resolve } from '@/utils/suspense';
import { defer, useLoaderData, } from 'react-router-dom';

import * as React from 'react';
/**
 * 用于定义页面路由的工具函数
 * 处理从 pages 文件夹中导入的页面模块
 */
export const parseModule = (module) => {
    const Component = React.lazy(() => module);
    const loader = async (...params) => {
        const fn = await getDynamicExport(module, 'getPageData');
        const data = fn?.(...params);
        return defer({
            data: Promise.resolve(data),
        });
    };
    return { Component, loader };
};
/**
 * 如果使用了 getPageData 函数，则可以使用此 hook 获取函数返回的数据
 */
export const usePageData = () => {
    const res = useLoaderData();
    return resolve(res.data);
};
